<template>
  <div>
    <b-card no-body>
      <!-- SINGLE DATA -->
      <b-modal
        ref="singleDataModal"
        centered
        :title="'Fichier: ' + singleData.slug"
        ok-only
        ok-title="Fermer"
      >
        <single-view :data="singleData" />
      </b-modal>
      <!-- SAVE/EDIT RECORD -->
      <b-modal
        id="setDataModal"
        ref="setDataModal"
        :title="
          Object.entries(this.modaldata.data).length === 0
            ? 'Nouveau'
            : 'Modification'
        "
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          {{
            Object.entries(this.modaldata.data).length === 0
              ? "Nouveau"
              : `Modification ${this.modaldata.data.title}`
          }}
          <hr />
          <small class="text-danger">* Champs obligatoire</small>
          <hr />
        </h3>

        <b-form @submit.prevent="save">
          <b-row>
            <b-col md="12">
              <!-- CATEGORY -->
              <b-form-group label="* Catégorie" label-for="category">
                <v-select
                  id="category"
                  label="text"
                  value="key"
                  v-model="formdata.category"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Sélectionner une categorie"
                  :options="formdata.categories"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <!-- SUBCATEGORY -->
              <div v-if="formdata.category">
                <b-form-group
                  v-if="
                    formdata.category.key === 'video_evenement_intern_extern'
                  "
                  label="Sous-categorie"
                  label-for="subcategory"
                >
                  <v-select
                    id="subcategory"
                    label="text"
                    value="key"
                    v-model="formdata.subcategory"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    placeholder="Sélectionner une sous-categorie"
                    :options="formdata.subcategories"
                  />
                </b-form-group>
              </div>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6">
              <!-- TYPE -->
              <b-form-group label="* Type de publication" label-for="mymetype">
                <v-select
                  id="mymetype"
                  label="text"
                  value="key"
                  v-model="formdata.type"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Sélectionner un type de publication"
                  :options="formdata.publishTypes"
                />
              </b-form-group>
            </b-col>
            <b-col md="6">
              <!-- METHOD -->
              <b-form-group label="* Méthode de publication" label-for="method">
                <v-select
                  id="method"
                  label="text"
                  value="key"
                  v-model="formdata.method"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  placeholder="Sélectionner une méthode de publication"
                  :options="formdata.publishMethods"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <!-- TITLE -->
          <b-form-group
            label="* Titre"
            label-for="title"
          >
            <b-form-input
              v-model="formdata.title"
              id="title"
              placeholder="Titre"
              required
            />
          </b-form-group>
          <!-- VIDEO SLUG -->
          <div v-if="formdata.type">
            <b-form-group
              v-if="formdata.type.key === 'video'"
              label="* Référence vidéo youtube - ex: T1xxicTjH64"
              label-for="videoSlug"
            >
              <b-form-input
                v-model="formdata.videoSlug"
                id="videoSlug"
                placeholder="Référence video youtube"
              />
            </b-form-group>
          </div>
          <!-- PUBLISH DATE -->
          <div v-if="formdata.method">
            <b-form-group
              v-if="formdata.method.key === 'delayed'"
              label="* Date de publication - ex: 17-03-2021"
              label-for="publishDate"
            >
              <cleave
                id="publishDate"
                v-model="formdata.date"
                class="form-control"
                :raw="false"
                :options="formdata.options.date"
                placeholder="DD-MM-YYYY"
              />
            </b-form-group>
          </div>
          <!-- FILE -->
          <div v-if="formdata.type">
            <b-form-group
              v-if="formdata.type.key === 'document'"
              label="Pièce jointe"
              label-for="file"
            >
              <b-form-file
                @change="updateCurrFile"
                v-model="formdata.file"
                id="file"
                accept="application/pdf"
                placeholder="Sélectionner un fichier"
              />
              <a
                class="text text-danger"
                v-if="formdata.file"
                @click="emptyFileInput"
                ><small>Retirer fichier</small></a
              >
            </b-form-group>
          </div>
          <!-- CONTENT -->
          <b-form-group
            label="Description"
            label-for="content"
          >
            <b-form-textarea
              rows="4"
              v-model="formdata.content"
              id="content"
              placeholder="Description"
            />
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('save')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Enregistrer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>
      <!-- IMPORT RECORD -->
      <b-modal
        id="importModal"
        ref="importModal"
        title="Importation"
        cancel-variant="outline-secondary"
        no-close-on-backdrop
        centered
        hide-footer
        hide-header
      >
        <h3 class="">
          Importation de liste de données
          <hr />
        </h3>

        <b-form @submit.prevent="importer">
          <!-- FILE -->
          <b-form-group
            label="Pièce jointe"
            label-for="file"
          >
            <b-form-file
              @change="this.$coddyger.updateCurrFile"
              v-model="formdata.file"
              id="file"
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              placeholder="Charger un fichier ou glissez-le ici..."
              drop-placeholder="Glisser un fichier ici..."
              size="md"
              no-drop
              required
            />
            <a
              class="text text-danger"
              v-if="formdata.file"
              @click="emptyFileInput"
              ><small>Retirer fichier</small></a
            >
          </b-form-group>

          <!-- submit and reset -->
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="reset"
                variant="outline-secondary"
                @click="closeModal('import')"
              >
                Fermer
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="submit"
                variant="primary"
                class="ml-1"
                style="float: right"
                :disabled="disableButtonOnLoading"
              >
                <span v-if="!isLoading">Importer</span>
                <span v-if="isLoading">
                  <b-spinner small type="grow"></b-spinner>
                  Chargement...
                </span>
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </b-modal>

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <v-select
              v-model="tabledata.perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="tabledata.pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block ml-50 mr-1"
            />
            <b-button class="mr-1" variant="primary" @click="openSaveDialog">
              Nouveau
            </b-button>
            <b-button variant="outline-primary" @click="openImportDialog">
              Importer
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4" offset-md="2">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="tabledata.filter"
                class="d-inline-block mr-1"
                placeholder="Recherche..."
              />
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refInvoiceListTable"
        :items="buildData"
        responsive
        :fields="tabledata.columns"
        primary-key="id"
        :current-page="tabledata.currentPage"
        :per-page="tabledata.perPage"
        :filter="tabledata.filter"
        show-empty
        empty-text="Auncune donnée disponible"
        class="position-relative"
        :busy.sync="tabledata.isBusy"
      >
        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-avatar
            :id="`invoice-row-${data.item.slug}`"
            size="32"
            :variant="`light-${postStatusVariantAndIcon(data.item.status).variant}`"
            :title="postStatusVariantAndIcon(data.item.status).text"
          >
            <feather-icon
              :icon="postStatusVariantAndIcon(data.item.status).icon"
            />
            
          </b-avatar>
        </template>
      
        <template #head(invoiceStatus)>
          <feather-icon icon="TrendingUpIcon" class="mx-auto" />
        </template>
        <template #cell(index)="data">
          {{ data.index + 1 }}
        </template>

        <template #table-busy>
          <div class="text-center my-2">
            <b-spinner small type="grow" />
            <strong> Veuillez patienter...</strong>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <feather-icon
              :id="`post-row-${data.item.slug}-preview-icon`"
              icon="EyeIcon"
              size="16"
              class="mr-1"
              @click.stop="openDialog(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-edit-icon`"
              icon="EditIcon"
              size="16"
              class="mr-1"
              @click.stop="buildEditModal(data.item)"
            />

            <feather-icon
              :id="`post-row-${data.item.slug}-trash-icon`"
              icon="TrashIcon"
              size="16"
              class=""
              @click.stop="deleteRecordPrompt(data.item.slug)"
            />
          </div>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted">Total: {{ buildData.length }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="tabledata.currentPage"
              :total-rows="tabledata.totalRows"
              :per-page="tabledata.perPage"
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BPagination,
  BModal,
  VBModal,
  BCardText,
  BForm,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormFile,
  BSpinner,
  BAvatar
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import Cleave from "vue-cleave-component";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { $themeConfig } from '@themeConfig'
import CommunicationStore from '@/store/app/communication/'
import * as payloads from './payloads.json'
import SingleView from './components/single-view.vue'

export default {
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BModal,
    BCardText,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormFile,
    BSpinner,
    BAvatar,

    vSelect,
    SingleView,
    Cleave,
  },
  data() {
    return {
      payloads: {
        paths: {
          select: '',
          save: '',
          edit: '',
          remove: '',
          importer: '',
        }
      },
      isLoading: false,
      singleData: [], // For single view mode
      locale: 'fr',
      isModalActive: false,
      modalData: [],
      // TABLE PARAMS
      tabledata: {
        isBusy: false,
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        pageOptions: [5, 10, 15, 30],
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        columns: [
          {
            key: 'index',
            label: '#',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'title',
            label: 'Titre',
            sortable: true,
            formatter: val => `${val}`,
          },
          {
            key: 'categoryName',
            label: 'Catégorie',
            sortable: true,
            formatter: val => `${val}`,
          },
          { key: "subcategoryName", label: "Sous-Categorie", sortable: true },
          { key: "status", label: "Etat", sortable: false },
          { key: 'actions' },
        ],
        searchTerm: '',
      },
      // Data NEW/EDIT Modal
      formdata: {
        slug: null,
        //-FORM-PROPERTIES-
        type: "",
        method: "",
        date: "",
        title: "",
        content: "",
        category: "",
        subcategory: "",
        file: null,
        videoSlug: "",

        categories: payloads.category,
        subcategories: payloads.subcategory,
        publishMethods: payloads.publishMethods,
        publishTypes: payloads.publishTypes,
        options: {
          date: {
            date: true,
            delimiter: "-",
            datePattern: ["d", "m", "Y"],
          },
        },
      },
      // MODAL TOOLS
      modaldata: {
        data: [],
        isModalActive: false,
      },
    }
  },
  computed: {
    buildData() {
      const { data } = this.$store.state.CommunicationStore
      this.tabledata.totalRows = data === undefined ? 0 : data.length

      return data === undefined ? [] : data
    },
    disableButtonOnLoading() {
      if (this.isLoading === true) {
        return true
      }
      return false
    },
  },
  created() {
    /* ----------------------------------------
    | Build payloads on component ready!
      ---------------------------------------- */
    // Build store
    this.buildStore()
    // Build payloads
    this.buildPayloads()
    // Build store data
    this.buildStoreData()
  },

  methods: {
    /* ----------------------------------------
    | APP STORE
      ---------------------------------------- */
    buildStore() {
      if (!CommunicationStore.isRegistered) {
        this.$store.registerModule('CommunicationStore', CommunicationStore)
        CommunicationStore.isRegistered = true
      }
    },
    /* ----------------------------------------
    | APP PAYLOADS
      ---------------------------------------- */
    buildPayloads() {
      let { path } = this.$router.history.current
      const getLastItem = thePath => thePath.substring(thePath.lastIndexOf('/') + 1)
      path = getLastItem(path)

      this.payloads = {
        route: `apps-post-${path}`,
        paths: {
          select: `${path}/select`,
          selectFile: `${path}/select/file`,
          save: `${path}/save`,
          edit: `${path}/edit`,
          remove: `${path}/remove`,
          importer: `${path}/import`,
        }
      }
    },
    buildStoreData() {
      this.tabledata.isBusy = true
      this.$store.dispatch('CommunicationStore/fetch', this.payloads.paths.select).then((doc) => {
        this.tabledata.isBusy = false
      }).catch(() => {
        this.tabledata.isBusy = false
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Erreur',
            icon: 'WarningIcon',
            variant: 'danger',
            text: 'Impossible de charger la liste des données dans le module '+this.payloads.paths.select+'. Merci de réessayer plutard.',
          },
        })
      })
    },

    emptyFileInput() {
      this.formdata.file = null;
    },
    closeModal(object) {
      if (object === 'save') {
        this.$refs.setDataModal.hide()
        this.resetValues()
      } else if (object === 'edit') {
        this.$refs.editModal.hide()
        this.resetValues()
      } else {
        this.$refs.importModal.hide()
      }
    },
    openSaveDialog() {
      this.$refs.setDataModal.show()
    },
    openImportDialog() {
      this.$refs.importModal.show()
    },
    swalError(title, message, type) {
      this.$swal({
        title,
        text: message,
        icon: type,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
    openLink(link) {
      window.open(link, '_blank')
    },
    openDialog(data) {
      this.$refs.singleDataModal.show()

      data.path = this.payloads.paths.selectFile

      this.singleData = data
    },

    /* ---------------------
    | RECORD REMOVAL HANDLER
    */
    deleteRecordPrompt(id) {
      this.$router.push({
        query: { ...this.$route.query, pid: id },
      })
      this.boxTwo = ''
      this.$bvModal
        .msgBoxConfirm('Voulez-vous supprimer cet enregistrement ?', {
          title: 'Confirmation',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Oui',
          cancelTitle: 'Non',
          cancelVariant: 'outline-danger',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.deleteData()
          } else {
            this.revertUrl()
          }
        })
    },
    deleteData() {
      const id = this.$route.query.pid
      if (id !== '' || id !== null) {
        this.$store
          .dispatch('CommunicationStore/remove', {
            item: id,
            path: this.payloads.paths.remove,
          })
          .then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Action Réussie',
                icon: 'CoffeeIcon',
                variant: 'success',
                text: 'Enregistrement supprimé avec succès!',
              },
            })
            this.revertUrl()
          })
          .catch(error => {
            this.$vs.notify({
              title: 'Erreur',
              text: error,
              color: 'danger',
              icon: 'error',
            })
          })
      }
    },
    // REVERT URL TO THE PREVIOUS
    revertUrl() {
      this.$router.replace({ name: this.payloads.route })
    },
    // MODAL FORM TOOL --------------
    resetValues() {
      this.formdata.slug = null
      this.formdata.title = ''
      this.formdata.category = []
      this.formdata.content = ''
      this.formdata.file = null
      this.formdata.type = ''
      this.formdata.method = ''
      this.formdata.date = ''
      this.formdata.videoSlug = ''
      this.modaldata.data = {}
    },
    save(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault()
      if (this.formdata.title === "") {
        this.swalError("Erreur", this.buildRequiredMessage("Titre"), "error");
      } else if (this.formdata.category === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Categorie"),
          "error"
        );
      } else if (this.formdata.type === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Type de publication"),
          "error"
        );
      } else if (this.formdata.method === "") {
        this.swalError(
          "Erreur",
          this.buildRequiredMessage("Méthode de publication"),
          "error"
        );
      } else {
        let global = {};
        let formData = new FormData();

        formData.append("slug", this.formdata.slug);
        formData.append("file", this.formdata.file);
        formData.append("title", this.formdata.title);
        formData.append("content", this.formdata.content);
        formData.append("category", this.formdata.category.key);
        formData.append("subcategory", (this.formdata.subcategory ? this.formdata.subcategory.key : ''));
        formData.append("mymetype", this.formdata.type.key);
        formData.append("publishMethod", this.formdata.method.key);
        formData.append("publishDate", this.formdata.date);
        formData.append("videoSlug", this.formdata.videoSlug);

        global = {
          type: 'form-data',
          formdata: formData,
          path: ((this.formdata.slug !== null && this.formdata.slug !== '') ? this.payloads.paths.edit : this.payloads.paths.save),
        }

        this.isLoading = true

        if (this.formdata.slug !== null && this.formdata.slug !== '') {
          this.$store
            .dispatch('CommunicationStore/edit', global)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Action Réussie',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Modification effectué avec succès!',
                },
              })
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)

              this.swalError('Erreur', error.message, 'error')
            })
        } else {
          this.$store
            .dispatch('CommunicationStore/save', global)
            .then(() => {
              this.isLoading = false
              this.$toast({
                component: ToastificationContent,
                position: 'top-right',
                props: {
                  title: 'Action Réussie',
                  icon: 'CoffeeIcon',
                  variant: 'success',
                  text: 'Enregistrement modifié avec succès!',
                },
              })
              this.resetValues()
            })
            .catch(error => {
              this.isLoading = false
              console.log(error)

              this.swalError('Erreur', error.message, 'error')
            })
        }
      }
    },
    importer(bvModalEvt) {
      // Prevent modal from closing
      bvModalEvt.preventDefault();
      if (this.formdata.file === "" || this.formdata.file === null) {
        this.swalError(
          "Erreur",
          "Veuillez sélectionner le fichier à importer",
          "error"
        );
      } else {
        let global = {};

        let formData = new FormData();

        formData.append("file", this.formdata.file);
        
        global = {
          formdata: formData,
          path: this.payloads.paths.importer,
        };
        
        this.isLoading = true;
        this.$store
          .dispatch("CommunicationStore/importItem", global)
          .then((doc) => {
            console.log(doc);
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: `Action Réussie`,
                icon: "CoffeeIcon",
                variant: "success",
                text: `Fichier importé avec succès! ${doc.success} lignes importées`,
              },
            });

            this.formdata.file = null;
            this.isLoading = false;
            this.$router.go();
          })
          .catch((error) => {
            let status = error.status;
            let message = error.message;
            let data = error.data;

            if (status === 422) {
              this.swalError("Erreur", message, "error");
            } else {
              this.swalError("Erreur", message, "error");
            }

            this.isLoading = false;
          });
      }
    },
    buildRequiredMessage(field) {
      return `Le champ ${field} est obligatoire`
    },
    buildEditModal(data) {
      this.modaldata.data = data
      this.modaldata.isModalActive = true

      if (!data) return
      if (Object.entries(data).length === 0) {
        this.resetValues()
      } else {
        this.formdata.slug = data.slug;
        this.formdata.title = data.title;
        this.formdata.content = data.content;
        this.formdata.videoSlug = data.videoSlug;
        this.formdata.date = data.publishDate;
        this.formdata.category = {
          key: data.category,
          text: data.categoryName,
        };
        this.formdata.subcategory = {
          key: data.subcategory,
          text: data.subcategoryName,
        };
        this.formdata.type = {
          key: data.mymetype,
          text: (data.mymetype === 'video' ? 'Vidéo' : 'Document'),
        };
        this.formdata.method = {
          key: data.publishMethod,
          text: (data.publishMethod === 'instant' ? 'Instantanée' : 'Programmée'),
        };
      }

      this.$refs.setDataModal.show()
    },
    updateCurrFile(e) {
      if (e.target.files && e.target.files[0]) {
        this.file = e.target.files[0];
      }
    },
  },
  setup() {
    // App Name
    const { appName, appLogoImage, appDesc } = $themeConfig.app
    const postStatusVariantAndIcon = status => {
      if (status === 'delayed') return { variant: 'warning', icon: 'ClockIcon', text: 'Programmée' }
      if (status === 'active') return { variant: 'success', icon: 'CheckCircleIcon', text: 'Active' }
      if (status === 'archived') return { variant: 'secondary', icon: 'BoxIcon', text: 'Archivé' }
      return { variant: 'secondary', icon: 'XIcon', text: 'Statut inconnu' }
    }

    return {
      appName,
      appLogoImage,
      appDesc,
      postStatusVariantAndIcon
    }
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
